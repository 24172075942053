import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from "./logoRender.png";
import logoUbicacion from "./logoUbicacion.png";
import logoTelefono from "./logoTelefono.png";
import logoMensaje from "./logoMensaje.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
`;

const Title = styled.h1`
  color: #333;
  margin-bottom: 20px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px; // Mantenemos el margen exterior como antes
  width: calc(33.33% - 40px);
  max-width: 400px;

  & > *:not(:last-child) {
    // Esto selecciona todos los elementos hijos de FormItem excepto el último
    margin-bottom: 15px; // Ajusta este valor según el espacio deseado entre elementos
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
`;

const StyledLabel = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const StyledSelect = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

function formatDateToSpanish(dateString) {
  // <-- Función para formatear la fecha
  const date = new Date(dateString);
  const days = [
    "DOMINGO",
    "LUNES",
    "MARTES",
    "MIÉRCOLES",
    "JUEVES",
    "VIERNES",
    "SÁBADO",
  ];
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  const dayName = days[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${dayName} ${day} ${month} ${year}`;
}

function App() {
  const [datos, setDatos] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedDate, setSelectedDate] = useState(""); // 1. Nuevo estado para la fecha seleccionada
  const [clientName, setClientName] = useState("");
  const [cityAndState, setCityAndState] = useState("");
  const [attention, setAttention] = useState("");
  const [observations, setObservations] = useState("");
  const [price, setPrice] = useState("");
  const [shipping, setShipping] = useState("");
  const [validity, setValidity] = useState("");
  const [delivery, setDelivery] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const fileInputRef = useRef(null);
  const [counter, setCounter] = useState(0);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  
  function highlightKeywords(text) {
    const keywords = ["estilo", "casco", "forro", "piel", "plantilla", "suela"];
    const words = text.split(/\b/);
    const highlightedText = [];
  
    words.forEach(word => {
      if (keywords.includes(word.toLowerCase())) {
        highlightedText.push({ text: capitalizeFirstLetter(word), bold: true });
      } else {
        highlightedText.push(word);
      }
    });
  
    return highlightedText;
  }
  
  const generatePDF = (
    description,
    date,
    logoBase64,
    logoUbicacionBase64,
    logoTelefonoBase64,
    logoMensajeBase64,
    clientName,
    cityAndState,
    attention
  ) => {
    const imageContent =
      uploadedImages.length > 0
        ? uploadedImages.map((img) => ({
            image: img,
            maxWidth: 555,
            maxHeight: 391,
            margin: [0, 10, 0, 10],
          }))
        : [];
    const docDefinition = {
      content: [
        {
          columns: [
            {
              image: logoBase64,
              width: 57,
            },
            {
              stack: [
                {
                  text: "www.calzadorender.com",
                  fontSize: 9,
                  margin: [9, 48, 0, 2],
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      image: logoUbicacionBase64,
                      width: 8,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: [
                        "San Juan de la Cruz 637,",
                        " Col. Camino Real,",
                        " CP 45040 Zapopan Jalisco.",
                      ],
                      fontSize: 8,
                      alignment: "left",
                      margin: [4, 2, 0, 2],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      image: logoTelefonoBase64,
                      width: 8,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: "(33) 3121 6362",
                      fontSize: 8,
                      alignment: "left",
                      margin: [4, 2, 0, 2], // <-- Margen alrededor para un ligero espacio
                    },
                  ],
                },
                {
                  columns: [
                    {
                      image: logoMensajeBase64,
                      width: 8,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: "ventas@calzadorender.com",
                      fontSize: 8,
                      alignment: "left",
                      margin: [4, 2, 0, 2], // <-- Margen alrededor para un ligero espacio
                    },
                  ],
                },
              ],
              alignment: "right",
              margin: [110, 0, 0, 0],
            },
          ],
          margin: [0, 0, 0, 0],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0, // <-- Cambiar a 0 para que la línea esté exactamente donde se dibuja
              x2: 510,
              y2: 0,
              lineWidth: 2, // <-- Aumentar el grosor de la línea a 2 (o más, según tu preferencia)
            },
          ],
          margin: [0, 2, 0, 10], // <-- Ajustar el margen superior para subir la línea
        },
        {
          text: `${capitalize(date)}`,
          alignment: "right",
          margin: [0, 0, 0, 10],
        },
        {
          text: `${clientName}`,
          margin: [0, 0, 0, 10],
        },
        {
          text: `${cityAndState}`,
          margin: [0, 0, 0, 10],
        },
        {
          text: `AT'N: ${attention}`,
          margin: [0, 0, 0, 10],
        },
        {
          text: "Por este medio le enviamos la cotización ficha tecnica de productos solicitada, no sin antes mandarle un cordial saludo.",
          alignment: "center",
          margin: [0, 10, 0, 10], // Agregamos un margen alrededor para separarlo del resto del contenido
        },
        {
          text: [
            { text: "Modelo: ", bold: true },
            capitalize(selectedValues.modelo) || "",
            "\n",
            { text: "Casco: ", bold: true },
            capitalize(selectedValues.casco) || "",
            "\n",
            { text: "Forro: ", bold: true },
            capitalize(selectedValues.forro) || "",
            "\n",
            { text: "Piel: ", bold: true },
            capitalize(selectedValues.piel) || "",
            "\n",
            { text: "Plantilla: ", bold: true },
            capitalize(selectedValues.plantilla) || "",
            "\n",
            { text: "Suela: ", bold: true },
            capitalize(selectedValues.suela) || "",
          ],
          margin: [0, 10, 0, 10], // Margen alrededor para separación
        },
        {
          text: highlightKeywords(capitalize(description)),
          alignment: "justify", // <-- Agrega esta línea
        },
        { text: `Observaciones: ${observations}`, margin: [0, 30, 0, 0] },
        {
          text: `Precio: $${parseFloat(price).toFixed(2)}`,
          margin: [0, 0, 0, 10],
        },
        { text: "Precios más 16% de I.V.A.", margin: [0, 20, 0, 0] },
        { text: `Envío: ${shipping}`, margin: [0, 0, 0, 0] },
        { text: `Vigencia de cotización: ${validity}`, margin: [0, 0, 0, 0] },
        { text: `Entrega: ${delivery}`, margin: [0, 0, 0, 0] },
        {
          text: "Garantia de 60 dias contra defecto de fabricacion.",
          margin: [0, 0, 0, 10],
        },
        {
          text: "Sin mas por el momento y en espera de vernos favorecidos nos despedimos quedando de ud. como sus attos. y s.s",
          alignment: "center",
          margin: [0, 20, 0, 10],
        },
        {
          text: "Atentamente \n",
          margin: [0, 20, 0, 10],
          alignment: "center",
        },
        {
          text:
            datos.persona_descripciones[
              datos.persona_nombres.indexOf(selectedValues.persona)
            ] || "",
          alignment: "center",
        },
        ...imageContent,
      ],
    };

    pdfMake.createPdf(docDefinition).download("cotizacion.pdf");
  };

  useEffect(() => {
    // Hacer la solicitud al servidor PHP
    fetch("https://coti.calzadorender.com/consul/consulta.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error en la respuesta del servidor");
        }
        return response.json();
      })
      .then((result) => {
        console.log("Datos recibidos:", result);
        setDatos(result);

        // Establecer los nombres iniciales
        const initialValues = {};
        Object.keys(result).forEach((key) => {
          if (key.endsWith("_nombres") && result[key].length > 0) {
            initialValues[key.replace("_nombres", "")] = result[key][0];
          }
        });

        setSelectedValues(initialValues);
      });
    // Obtener el valor actual del contador
    axios
      .get("https://coti.calzadorender.com/consul/api.php", {
        headers: {
          Authorization: "Bearer lRDZaA-lY5JkPAJETgprq9uV6DeteqkZR9y1zmzluVc",
        },
      })
      .then((response) => {
        setCounter(response.data.counter);
      })
      .catch((error) => {
        console.error("Hubo un error al obtener los datos:", error);
      });
  }, []);

  const handleSelectChange = (e, tableName) => {
    const { value } = e.target;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [tableName]: value,
    }));
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const imgToBase64 = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const incrementCounter = () => {
    axios
      .post(
        "https://coti.calzadorender.com/consul/api.php",
        {},
        {
          headers: {
            Authorization: "Bearer lRDZaA-lY5JkPAJETgprq9uV6DeteqkZR9y1zmzluVc",
          },
        }
      )
      .then((response) => {
        setCounter(response.data.counter);
      })
      .catch((error) => {
        console.error("Hubo un error al incrementar el contador:", error);
      });
  };

  const handleGenerateDescription = () => {
    let descriptions = "";
    Object.keys(selectedValues).forEach((tableName) => {
      if (tableName !== "persona") {
        const selectedIndex = datos[`${tableName}_nombres`].indexOf(
          selectedValues[tableName]
        );
        if (selectedIndex !== -1) {
          descriptions +=
            datos[`${tableName}_descripciones`][selectedIndex] + " ";
        }
      }
    });

    const formattedDate = formatDateToSpanish(selectedDate);

    imgToBase64(logo, (base64Img) => {
      imgToBase64(logoUbicacion, (base64Ubicacion) => {
        imgToBase64(logoTelefono, (base64Telefono) => {
          imgToBase64(logoMensaje, (base64Mensaje) => {
            generatePDF(
              descriptions.trim(),
              formattedDate,
              base64Img,
              base64Ubicacion,
              base64Telefono,
              base64Mensaje,
              clientName,
              cityAndState,
              attention
            );
          });
        });
      });
    });
    // Incrementar el contador después de generar el PDF
    incrementCounter();
  };
  if (!datos || Object.keys(datos).length === 0) {
    return (
      <AppContainer>
        <Title>Cotizador RENDER</Title>
        <p>Cargando datos...</p>
      </AppContainer>
    );
  }

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(files[i]);
        })
      );
    }

    Promise.all(promises).then((base64Files) => {
      setUploadedImages((prevImages) => [...prevImages, ...base64Files]);
    });
  };

  const handleCancel = () => {
    setUploadedImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
  }
  };

  const handleLogin = () => {
    // Aquí puedes agregar una lógica para verificar las credenciales con una base de datos
    if (username === "Render" && password === "Cotizador") {
      setIsAuthenticated(true);
    } else {
      alert("Usuario o contraseña incorrectos.");
    }
  };

  if (!isAuthenticated) {
    return (
      <AppContainer>
        <Title>R E N D E R</Title>
        <FormItem>
          <StyledLabel>Usuario:</StyledLabel>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <StyledLabel>Contraseña:</StyledLabel>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={handleLogin}>Iniciar sesión</Button>
        </FormItem>
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <Title>Cotizador RENDER</Title>
      <p>Número de cotizaciones generadas: {counter}</p>
      <FormRow>
        <FormItem>
          <StyledLabel>Fecha:</StyledLabel>
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>Nombre del cliente o empresa:</StyledLabel>
          <input
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>Ciudad y estado:</StyledLabel>
          <input
            type="text"
            value={cityAndState}
            onChange={(e) => setCityAndState(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>AT'N:</StyledLabel>
          <input
            type="text"
            value={attention}
            onChange={(e) => setAttention(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
        </FormItem>
        <FormItem>
          {Object.keys(datos)
            .filter((key) => key.endsWith("_nombres"))
            .map((tableName) => {
              const pureTableName = tableName.replace("_nombres", "");
              return (
                <div key={pureTableName}>
                  <StyledLabel>{pureTableName}:</StyledLabel>
                  <StyledSelect
                    value={selectedValues[pureTableName] || ""}
                    onChange={(e) => handleSelectChange(e, pureTableName)}
                  >
                    {datos[tableName].map((nombre, index) => (
                      <option key={index} value={nombre}>
                        {nombre}
                      </option>
                    ))}
                  </StyledSelect>
                </div>
              );
            })}
        </FormItem>
        <FormItem>
          <StyledLabel>Observaciones:</StyledLabel>
          <input
            type="text"
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>Precio:</StyledLabel>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>Envio:</StyledLabel>
          <input
            type="text"
            value={shipping}
            onChange={(e) => setShipping(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>Vigencia de cotizacion:</StyledLabel>
          <input
            type="text"
            value={validity}
            onChange={(e) => setValidity(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>Entrega:</StyledLabel>
          <input
            type="text"
            value={delivery}
            onChange={(e) => setDelivery(e.target.value)}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: "400px",
            }}
          />
          <StyledLabel>Imágenes:</StyledLabel>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            multiple
            onChange={(e) => handleImageUpload(e)}
          />
          <button onClick={handleCancel}>Cancelar</button>
          {/* <div>
            {uploadedImages.map((image, index) => (
              <img key={index} src={image} alt={`Imagen ${index + 1}`} />
            ))}
          </div> */}
        </FormItem>
      </FormRow>
      <Button onClick={handleGenerateDescription}>Crear Cotización</Button>
    </AppContainer>
  );
}

export default App;
